import { ref } from 'vue'

export type LoadedAudioType = HTMLAudioElement | null

export const loadedAudio = ref<LoadedAudioType>(null)

export function preloadAudio(path: string): void {

    const audio = new Audio(path)

    audio.load()
    loadedAudio.value = audio

}

export function playPreloadedAudio(): void {

    const audio: LoadedAudioType = loadedAudio.value

    if (audio) {
        audio.play()
    }

}
