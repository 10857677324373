<template>

    <div v-if="content.showTcpa" class="m-5 text-xs text-justify tcpa">

        <label for="leadid_tcpa_disclosure">
            <input id="leadid_tcpa_disclosure" type="hidden">
            <span v-html="tcpa"/>
        </label>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { state, WebFormStep } from '../../../types/State'

    export default defineComponent({
        name: 'TCPA',
        props: {
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup() {

            return {
                tcpa: state.webForm.content.tcpa || state.domain.content.tcpa,
            }

        },
    })

</script>

<style lang="scss">

    .tcpa span span.modal-link {
        @apply text-blue-400 font-semibold underline cursor-pointer
    }

</style>
